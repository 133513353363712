<template>
    <div>
        <div class="mybox bg-white padding-sm">
            <el-tabs v-model="activeName" >
                <el-tab-pane label="待面试" name="Waiting"></el-tab-pane>
                <el-tab-pane label="面试成功" name="Success"></el-tab-pane>
                <el-tab-pane label="面试失败" name="Fail"></el-tab-pane>
            </el-tabs>
            <component :is="activeName" />
        </div>

    </div>
</template>
<script>
import Waiting from './waiting'
import Success from './success'
import Fail from './fail'

export default {
    components:{
        Waiting,
        Success,
        Fail,
    },
    data(){
        return{
            activeName:"Waiting",
        }
    },
    created() {
    },
    methods:{

    }
}
</script>
<style scoped>
.mybox{ width: 1200px; margin: 15px auto;}
</style>

